import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jricher/Projects/oauth.xyz-site/src/components/layout.js";
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="About" keywords={['oauth', 'authorization', 'security']} mdxType="SEO" />
    <h1>{`About`}</h1>
    <p>{`Justin Richer of `}<a href="https://bspk.io/">{`Bespoke Engineering`}</a>{` proposed the XYZ concept and project based on experience and conversations with a wide variety of industry experts. Concepts incorporated into XYZ come from many different projects in the internet security and identity space, and have been referenced where applicable. The protocol aspect of XYZ has been subsumed by `}<a href="https://datatracker.ietf.org/doc/draft-ietf-gnap-core-protocol/">{`GNAP`}</a>{`, and XYZ is now most properly used to refer to several specific implementations of GNAP. The examples on these pages and discussion are therefore now primarily about GNAP and not specific to the XYZ implementations.`}</p>
    <p>{`This work has been supported in part by `}<a href="https://securekey.com">{`SecureKey`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      